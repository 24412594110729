import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED with the INSTAR Cloud Webhook",
  "path": "/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/",
  "dateChanged": "2022-03-29",
  "author": "Mike Polinowski",
  "excerpt": "Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Node-RED with the INSTAR Cloud Webhook' dateChanged='2022-03-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_mit_INSTAR_Cloud_Webhook/' locationFR='/fr/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.`}</p>
    <h2 {...{
      "id": "node-red-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-webhook",
        "aria-label": "node red webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Webhook`}</h2>
    <p>{`The following tutorial will lead you through the setup process of Node-RED in Docker and configuration of a `}<strong parentName="p">{`GET`}</strong>{` and `}<strong parentName="p">{`POST`}</strong>{` webhook that you can use with your INSTAR Cloud account:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/Webhook/"
        }}>{`The INSTAR Cloud Webhook`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation"
            }}>{`Installing Node-RED v2 with Docker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_GET_Webhook"
            }}>{`Node-RED GET Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_POST_Webhook"
            }}>{`Node-RED POST Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy"
            }}>{`Node-RED adding Encryption with an HTTP/2 Webproxy`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      